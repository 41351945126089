import { Container, Row, Col, Nav, Tab } from "react-bootstrap"
import logo from '../assets/img/jorim_logo.png'
import navIcon1 from '../assets/img/nav-icon1.svg'
import navIcon2 from '../assets/img/nav-icon2.svg'
import navIcon3 from '../assets/img/nav-icon3.svg'

export const Footer = () => {
    return(
        <footer className="footer">
            <Container>
                <Row className="align-item-center">
                    <Col sm={6}>
                        <img src={logo} alt="Logo" />
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className="social-icon">
                            <a href="https://www.linkedin.com/in/jorimtan/" target="_blank" rel="noopener noreferrer"><img src={navIcon1} alt="" /></a>
                        </div>
                        <p>© 2024 Jorim Tan. All rights reserved.</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}