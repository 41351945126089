import { Container, Row, Col } from "react-bootstrap"

export const ProjectCardLinkable = ({ title, description, imgUrl, linkUrl }) => {
    if(linkUrl===""){
        return (
            <Col sm={6} md={4}>
                <div className="proj-imgbx">
                    <img src={imgUrl} />
                    <div className="proj-txtx">
                        <h4>{title}</h4>
                        <span>{description}</span>
                    </div>
                </div>
            </Col>
        )
    } else {
        return (
            <Col sm={6} md={4}>
                <div className="proj-imgbx">
                    <img src={imgUrl} />
                    <div className="proj-txtx">
                        <h4>{title}</h4>
                        <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                            <span className="text-white">{description}</span>
                        </a>
                    </div>
                </div>
            </Col>
        )
    }
}