import { Container, Row, Col } from "react-bootstrap"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meter100 from "../assets/img/meter100.png"
import meter95 from "../assets/img/meter95.png"
import meter90 from "../assets/img/meter90.png"
import meter85 from "../assets/img/meter85.png"
import meter80 from "../assets/img/meter80.png"
import colourSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className="skill-bx-top">
                            <h2>
                                Development Skills
                            </h2>
                            <p>Full-stack trained and able to handle databases.</p>
                            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} className="skill-slider">
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>HTML & CSS</h5>
                                </div>
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>Javascript (React)</h5>
                                </div>
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>Svelte</h5>
                                </div>
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>Tailwind CSS</h5>
                                </div>
                                <div className="item">
                                    <img src={meter90} alt="Image" />
                                    <h5>NodeJS</h5>
                                </div>
                                <div className="item">
                                    <img src={meter90} alt="Image" />
                                    <h5>PostgreSQL</h5>
                                </div>
                                <div className="item">
                                    <img src={meter85} alt="Image" />
                                    <h5>MySQL</h5>
                                </div>
                                <div className="item">
                                    <img src={meter90} alt="Image" />
                                    <h5>Git</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                    <Col>
                        <div className="skill-bx-bot">
                            <h2>
                                Design Skills
                            </h2>
                            <p>Multimedia designer well versed with Adobe Creative software, UI/UX expert.</p>
                            <Carousel responsive={responsive} infinite={true} autoPlay={true} autoPlaySpeed={2000} className="skill-slider">
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>Photoshop</h5>
                                </div>
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>Illustrator</h5>
                                </div>
                                <div className="item">
                                    <img src={meter95} alt="Image" />
                                    <h5>After Effects</h5>
                                </div>
                                <div className="item">
                                    <img src={meter80} alt="Image" />
                                    <h5>Figma</h5>
                                </div>
                                <div className="item">
                                    <img src={meter90} alt="Image" />
                                    <h5>Videography</h5>
                                </div>
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className="background-image-left" src={colourSharp} />
        </section>
      )
}