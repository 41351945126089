import { Container, Row, Col, Nav, Tab } from "react-bootstrap"
import { ProjectCard } from "./ProjectCard"
import { ProjectCardLinkable } from "./ProjectCardLinkable"
import colorSharp2 from "../assets/img/color-sharp2.png"
import waProjImg1 from "../assets/img/3dModel_redacted_m.png"
import waProjImg2 from "../assets/img/drawOnMap_cropped_m.png"
import waProjImg3 from "../assets/img/drawTools_redacted_m.png"
import waProjImg4 from "../assets/img/threejs_redacted_m.png"
import wsProjImg1 from "../assets/img/jorimtan_m.png"
import wsProjImg2 from "../assets/img/jorimtan-omnimed01_m.png"
import wsProjImg3 from "../assets/img/jorimtan-caesarproduction01.jpg"
import wsProjImg4 from "../assets/img/jorimtan-vida-resources_m.jpg"

export const Projects = () => {

    const waprojects = [
        {
            title: "3D Model in Map",
            description: "Importing, rendering and manipulation of 3D objects.",
            imgUrl: waProjImg1,
        },
        {
            title: "Draw on Map",
            description: "Customising modules to achieve requested outcome and UI.",
            imgUrl: waProjImg2,
        },
        {
            title: "Drawing Tools",
            description: "Targeted code to interact with database and process data.",
            imgUrl: waProjImg3,
        },
        {
            title: "Three.js Experience",
            description: "Load floor plans in 3D space with various points of interaction.",
            imgUrl: waProjImg4,
        },
    ]

    const wsprojects = [
        {
            title: "jorimtan.com",
            description: "Live at... 🤨",
            imgUrl: wsProjImg1,
            linkUrl: ""
        },
        {
            title: "OmniMed Healthcare",
            description: "Live at www.omnimedhealthcare.com",
            imgUrl: wsProjImg2,
            linkUrl: "https://www.omnimedhealthcare.com/"
        },
        {
            title: "Caesar Production",
            description: "Live at www.caesarproduction.com",
            imgUrl: wsProjImg3,
            linkUrl: "http://www.caesarproduction.com/"
        },
        {
            title: "Vida Resources",
            description: "Was live at www.vida-resources.com",
            imgUrl: wsProjImg4,
            linkUrl: ""
        },
    ]

    const dprojects = [
        {
            title: "Past Showreel",
            description: "Watch on Vimeo",
            imgUrl: wsProjImg1,
        },
    ]

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                        <p>Have a gander at some of my past works.</p>
                        <Tab.Container id="projects-tab" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Web Apps</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Web Sites</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third">Design</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            waprojects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                        />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            wsprojects.map((project, index) => {
                                                return (
                                                    <ProjectCardLinkable
                                                        key={index}
                                                        {...project}
                                                        />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    <Row>
                                        <Col xs={12} xl={6}>
                                            <div className="proj-vidbx">
                                                <p>New video coming soon!</p>
                                                <iframe title="Coming Soon" src="" width="100%" height="" allowFullScreen></iframe>
                                            </div>
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <div className="proj-vidbx">
                                                <iframe title="秋戰3" src="https://player.vimeo.com/video/137347027" width="100%" height="360" allowFullScreen></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} xl={6}>
                                            <div className="proj-vidbx">
                                                <iframe title="Showreel 2013" src="https://player.vimeo.com/video/105214520" width="100%" height="360" allowFullScreen></iframe>
                                            </div>
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <div className="proj-vidbx">
                                                <iframe title="Showreel 2012" src="https://player.vimeo.com/video/55010365" width="100%" height="360" allowFullScreen></iframe>
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                        
                    </Col>
                </Row>
            </Container>
            <img className="background-image-right" src={colorSharp2}></img>
        </section>
    )
}